import * as React from 'react'
import Template from "../components/template";
import "./projects.css";
import Project from "../components/project";
import {projectsData} from "../components/projects_data"

const Page = () => {

    const projectsEl = projectsData.map((p, index) => {
        return <Project p={p} key={"project-item-" + index}/>
    })
    return (
        <Template page="projects" title="Projects">
            <div className="blocks">
                {projectsEl}
            </div>
        </Template>
    )
}
export default Page
